import React, {
  useEffect,
  useMemo,
  // useState
} from "react";
import {
  PageTitle,
  Loader,
  // Dropdown, Backdrop
} from "../../../components";
import {
  Image,
  // Input, Select, Tooltip
} from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSliders } from "@fortawesome/free-solid-svg-icons";
// import {
//   faCopy,
//   faEdit,
//   // faQuestionCircle,
//   faTrashAlt,
// } from "@fortawesome/free-regular-svg-icons";
import {
  DEFAULT_PROJECT_THUMB,
  // MODEL_OPTIONS,
  PROJECT_ROUTE,
  // PROJECT_SORT_OPTIONS,
  PROJECT_STATUS_CLASSES,
  PROJECT_ROLES,
  // USE_CASES_OPTIONS,
} from "../../../constants";
import { CustomDispatch } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProjectsRequest } from "../../../redux/slicers/project";
import clsx from "clsx";
import "./styles.scss";

const Projects = () => {
  // STATES
  // const [filterPreview, setfilterPreview] = useState(false);
  // const [sortBy, setSortBy] = useState(PROJECT_SORT_OPTIONS[0]);

  // CONST VALS
  const navigate = useNavigate();

  // REDUX DATA
  const projects = useSelector(({ project }) => project.projects);

  // CUSTOM DISPATCH
  const [getProjects, isLoading] = CustomDispatch(getProjectsRequest);

  // HANDLERS
  const navigateToProject = (id, isSharedProject) => {
    if (!isSharedProject) navigate(PROJECT_ROUTE.replace(":projectslug", id));
    else window.open(id, "_blank");
  };
  // const sortHandler = (val) => {
  //   setSortBy(val);
  // };
  // const filterHandler = () => {
  //   setfilterPreview(!filterPreview);
  // };

  // HOOKS
  useEffect(() => {
    const payload = {
      resource: "project",
      method: "list",
    };
    getProjects({ payload });
  }, []);

  // CUSTOM COMPONENT
  // const placeholder = () => (
  //   <span>
  //     Sort: <span>{sortBy.label}</span>
  //   </span>
  // );

  const projectsListing = useMemo(() => {
    // show loader while fetching data
    if (isLoading) return <Loader height="100%" size={14} />;

    // show empty box if no projects
    if (!projects.length)
      return <h1 className="no-data">No project created</h1>;

    return (
      <div className="data-listing">
        {projects.map(
          (
            {
              id,
              projectName,
              thumb,
              projectDescription,
              projectStatus,
              role,
              publicUrl,
            },
            index
          ) => {
            const isViewer = role === PROJECT_ROLES.VIEWER;
            const navigateHandler = () =>
              navigateToProject(isViewer ? publicUrl : id, isViewer);
            return (
              <div
                role="button"
                tabIndex={-1}
                className="list-item"
                key={index}
                onClick={navigateHandler}
                onKeyUp={navigateHandler}
              >
                {PROJECT_STATUS_CLASSES?.[projectStatus] && (
                  <span
                    className={clsx(
                      "tagline",
                      PROJECT_STATUS_CLASSES[projectStatus]
                    )}
                  />
                )}
                <div className="thumb">
                  <Image
                    src={thumb}
                    alt={projectName}
                    preview={false}
                    fallback={DEFAULT_PROJECT_THUMB}
                  />
                </div>
                <div className="detail">
                  <h3>{projectName}</h3>
                  <p>{projectDescription}</p>
                </div>
                {role === PROJECT_ROLES.VIEWER && (
                  <span className={clsx("tag")}>Shared</span>
                )}
                {/* <div className="action-box">
                <FontAwesomeIcon icon={faEdit} />
                <FontAwesomeIcon icon={faCopy} />
                <FontAwesomeIcon icon={faTrashAlt} />
              </div> */}
              </div>
            );
          }
        )}
      </div>
    );
  }, [projects, isLoading]);

  return (
    // <div className="coming-soon">
    //   <h1>Coming Soon</h1>
    // </div>
    <div className="projects">
      <PageTitle title="MyAI Projects" />
      {/* <div className="filteration-box customize-form">
        <div className="search-box">
          <label htmlFor="search">Search</label>
          <div className="input-box">
            <Input id="search" type="text" />
            <button className="filter-btn" onClick={filterHandler}>
              <FontAwesomeIcon icon={faSliders} />
            </button>
          </div>
        </div>
        {filterPreview && <Backdrop onClick={filterHandler} zIndex={99} />}
        <div className={clsx("filter-drawer", filterPreview && "active")}>
          <div className="model-box">
            <label htmlFor="model">
              Filter by model
              <Tooltip title="Filter projects by model">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </label>
            <Select id="model" placeholder="Select Model(s)">
              {MODEL_OPTIONS.map((model) => (
                <Select.Option key={model.value} value={model.value}>
                  {model.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="usecase-box">
            <label htmlFor="usecase">
              Filter by use case
              <Tooltip title="Filter projects by use cases">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </label>
            <Select id="usecase" placeholder="Select Use Case(s)">
              {USE_CASES_OPTIONS.map((usecase) => (
                <Select.Option key={usecase.value} value={usecase.value}>
                  {usecase.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <Dropdown
          className="sort-dropdown"
          placeholder={placeholder()}
          options={PROJECT_SORT_OPTIONS}
          optionBox={(option) => option.label}
          isSelected={(item) => item.value === sortBy.value}
          selectHandler={sortHandler}
        />
      </div> */}
      <div className="data-wrapper">{projectsListing}</div>
    </div>
  );
};

export default Projects;
