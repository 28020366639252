import React from "react";
import { useSelector } from "react-redux";
import { Col, Image, Row } from "antd";
import { DEFAULT_PROJECT_THUMB } from "../../../../../constants";
import "./styles.scss";

const SplashScreen = ({ sendMessage = () => {} }) => {
  // REDUX DATA
  const data = useSelector((state) => state.project.selectedProject);
  const fieldsView = useSelector((state) => state.project.fieldsVisibility);
  const fieldsValues = useSelector((state) => state.project.fieldsValues);

  return (
    <div className="splash-screen">
      <div className="basic-detail">
        <Image
          preview={false}
          fallback={DEFAULT_PROJECT_THUMB}
          alt={data?.projectName}
        />
        <div className="detail">
          <h3 style={{ opacity: fieldsView.title ? 1 : 0 }}>
            {fieldsValues.title}
          </h3>
          <p style={{ opacity: fieldsView.subtitle ? 1 : 0 }}>
            {fieldsValues.subtitle}
          </p>
        </div>
      </div>
      <div className="chat-starters">
        <Row gutter={(24, 24)}>
          {data?.starterGroups?.map((item, index) => (
            <Col xs={24} md={8} key={index}>
              <div className="starter-box">
                <h3>{item.title}</h3>
                <div className="starters">
                  {item.starters.map((starter, i) => (
                    <button key={i} onClick={() => sendMessage(starter)}>
                      {starter}
                    </button>
                  ))}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default SplashScreen;
