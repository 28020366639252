import React, { useState } from "react";
import { Button } from "../../../";
import { CardBox } from ".";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { faCode, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCopy,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "antd";

const ApiGenerationStep = ({
  data = {},
  previousStepHander = () => {},
  nextStepHandler = () => {},
}) => {
  // CONST VALS
  const PUBLIC_URL = "https://platform.aiml.asu.edu/k1ml3k";

  // HANDLERS
  const submitHandler = () => {
    nextStepHandler({
      publicUrl: PUBLIC_URL,
    });
  };

  // CUSTOM COMPONENT
  const UrlViewBox = ({
    title,
    value,
    copiedMessage,
    className = "",
    actionPreview,
    editHandler = () => {},
    deleteHandler = () => {},
  }) => {
    // STATES
    const [isCopied, setCopied] = useState(false);

    // HANDLERS
    const handleCopy = () => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    };

    return (
      <div className="url-view-box-wrapper">
        <div className={`url-view-box ${className}`}>
          <span className="title">{title}</span>
          <span className="value">{value}</span>
          <div className="action-box">
            <CopyToClipboard text={copiedMessage} onCopy={handleCopy}>
              <Tooltip title={isCopied ? "Copied" : "Copy"}>
                <FontAwesomeIcon icon={isCopied ? faCircleCheck : faCopy} />
              </Tooltip>
            </CopyToClipboard>
            {actionPreview && (
              <>
                <Tooltip title="Edit">
                  <FontAwesomeIcon icon={faEdit} onClick={editHandler} />
                </Tooltip>
                <Tooltip title="Delete">
                  <FontAwesomeIcon icon={faTrashAlt} onClick={deleteHandler} />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="api-generation-step">
      {/* API GENERATION */}
      <CardBox icon={faCode} title="API Generation">
        <UrlViewBox
          title="REST API"
          className="generation-key"
          copiedMessage="https://main-asullm.ai.asu.edu/k1ml3k"
          value="https://main-asullm.ai.asu.edu/k1ml3k"
        />
        <UrlViewBox
          title="Websocket API"
          className="generation-key mt-15"
          copiedMessage="wss://mainws-asullm.asu.edu/k1lml3k"
          value="wss://mainws-asullm.asu.edu/k1lml3k"
        />
      </CardBox>
      {/* API KEYS */}
      <CardBox
        icon={faCode}
        title="API Keys"
        description="Below are your confidential API keys. Please do not share them with others or expose them in the browser or any client-side code.">
        <UrlViewBox
          title="Key 1"
          className="api-key"
          actionPreview
          copiedMessage="Sk-12R21sbbw1sfq3198w4"
          value="Sk-. . . 98w4"
        />
        <UrlViewBox
          title="Key 2"
          className="api-key mt-15"
          actionPreview
          copiedMessage="Sk-12R21sbbw1sfq31eRR5"
          value="Sk-. . . eRR5"
        />
        <Button className="regenerate-secret-btn mt-20">
          <FontAwesomeIcon icon={faPlus} />
          Regenerate secret keys
        </Button>
      </CardBox>
      {/* PUBLIC URL */}
      {data?.webInterface && (
        <CardBox icon={faCode} title="Public Url">
          <UrlViewBox
            title="Public Url"
            copiedMessage={data?.publicUrl}
            value={data?.publicUrl}
          />
        </CardBox>
      )}
      <div className="bottom-wrapper">
        <Button className="previous-step-btn" onClick={previousStepHander}>
          Back
        </Button>
        <Button invertedTheme className="next-step-btn" onClick={submitHandler}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default ApiGenerationStep;
