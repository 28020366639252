import React, { useEffect } from "react";
import { Loader } from "../../components";
import {
  getUserAccessRequest,
  getUserDetailsRequest,
} from "../../redux/slicers/user";
import { getUserAsurite } from "../../utils";
import { CustomDispatch } from "../../helpers";

const PrivateSharedLayout = ({ children }) => {
  // CUSTOM DISPATCH
  const [getUserDetail] = CustomDispatch(getUserDetailsRequest);
  const [getUserAccess, acessLoader] = CustomDispatch(
    getUserAccessRequest,
    true
  );

  // HELPERS
  const getUserDetailHelper = () => {
    const userAsurite = getUserAsurite();
    if (userAsurite) {
      const queryParams = { asurite_ids: userAsurite };
      getUserDetail({ loggedinUser: true, queryParams });
    }
  };
  const getUserAccessHelper = () => {
    const payload = {
      resource: "user",
      method: "describe",
    };
    getUserAccess({ payload });
  };

  // HOOKS
  useEffect(() => {
    getUserDetailHelper();
    getUserAccessHelper();
  }, []);

  if (acessLoader) return <Loader />;
  return children;
};

export default PrivateSharedLayout;
