import React, { useState, useEffect } from "react";
// import clsx from "clsx";
import { Form, Input, Switch } from "antd";
import { AccordionBox, FormActions, LabelBox } from "..";
import { inputFieldRule } from "../../../../../../utils";
import { useSelector } from "react-redux";
import { Button } from "../../../../../../components";
import { CustomDispatch } from "../../../../../../helpers";
import { editProjectRequest } from "../../../../../../redux/slicers/project";
import { manipulateProjectDetailPayload } from "../../../../../../data-manipulator/project";

const DetailAccordion = ({ deleteHandler, changeTab }) => {
  // STATES
  const [switchFields, setswitchFields] = useState({
    enableHistory: false,
    saveAsTemplate: false,
    shareTemplate: false,
  });
  const [resetPreview, setResetPreview] = useState(false);
  const [applyPreview, setapplyPreview] = useState(false);

  // CONST VALS
  const [form] = Form.useForm();

  // CUSTOM DISPATCH
  const [editProject, isLoading] = CustomDispatch(editProjectRequest);

  // HELPERS
  const setFieldsValuesHelper = () => {
    form.setFieldsValue(data);
    setswitchFields({ ...switchFields, enableHistory: data.enableHistory });
  };
  const editProjectHelper = (values) => {
    const details = { ...values, ...switchFields, id: data.id };

    const payload = {
      resource: "project",
      method: "update",
      details: manipulateProjectDetailPayload(details),
    };
    editProject({
      payload,
      callback: (status) => {
        setResetPreview(!status);
        setapplyPreview(!status);
      },
    });
  };

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);

  // HANDLERS
  const fieldChangeHandler = (status = true) => {
    setResetPreview(status);
    setapplyPreview(status);
  };
  const switchFieldsHandler = (name) => {
    fieldChangeHandler();
    setswitchFields({ ...switchFields, [name]: !switchFields[name] });
  };
  const resetHandler = () => {
    setFieldsValuesHelper();
    fieldChangeHandler(false);
  };
  const applyHandler = () => {
    form
      .validateFields()
      .then((values) => {
        fieldChangeHandler(false);
        editProjectHelper(values);
      })
      .catch(() => {});
  };

  // HOOKS
  useEffect(() => {
    if (data) setFieldsValuesHelper();
  }, [data]);

  return (
    <AccordionBox title="Project" preview>
      <Form
        form={form}
        className="customize-form"
        onFieldsChange={() => fieldChangeHandler()}
      >
        {/* PROJECT BASIC SETTING */}
        {/* <div className="basic-settings">
          <h3 className="title">
            Basic settings
            <button type="button" onClick={changeTab}>
              Edit
            </button>
          </h3>
          <div className="detail inline">
            <p>Title: </p>
            <h4>{data.projectName}</h4>
          </div>
          <div className="detail">
            <h4>Custom instruction:</h4>
            <p>{data.systemPrompt}</p>
          </div>
        </div>
        <hr /> */}
        {/* PROJECT DESCRIPTION */}
        <LabelBox
          title="Project description"
          name="projectDescription"
          message="Provide a brief description of your project. This helps others understand its purpose and key objectives."
        />
        <Form.Item
          name="projectDescription"
          rules={inputFieldRule({
            name: "Project description",
            isMax: true,
            max: 240,
          })}
        >
          <Input.TextArea id="projectDescription" rows={5} />
        </Form.Item>
        <hr />
        {/* CHAT MEMORY */}
        <div className="label-box">
          <LabelBox
            name="enableHistory"
            title="Enable chat memory"
            message="Chat memory stores previous interactions, allowing the AI to reference past conversations for more context-aware responses. You can clear or disable memory if you prefer not to save interactions."
          />
          <Switch
            id="enableHistory"
            checked={switchFields.enableHistory}
            onChange={() => switchFieldsHandler("enableHistory")}
          />
        </div>
        <hr />
        {/* SAVE AS TEMPLATE */}
        {/* <div
          className={clsx(
            "label-box",
            switchFields.saveAsTemplate && "form-item"
          )}
        >
          <LabelBox
            name="saveAsTemplate"
            title="Save as template"
            message="Save this project as template for future use"
          />
          <Switch
            id="saveAsTemplate"
            checked={switchFields.saveAsTemplate}
            onChange={() => switchFieldsHandler("saveAsTemplate")}
          />
        </div>
        {switchFields.saveAsTemplate && (
          <Form.Item
            name="templateName"
            rules={inputFieldRule({
              name: "Template name",
              isMax: true,
              max: 120,
            })}
          >
            <Input placeholder="Template name" />
          </Form.Item>
        )} */}
        {/* SHARE TEMPLATE */}
        {/* <div className="label-box">
          <LabelBox
            name="shareTemplate"
            title="Share template"
            message="share this template with other users"
          />
          <Switch
            id="shareTemplate"
            checked={switchFields.shareTemplate}
            onChange={() => switchFieldsHandler("shareTemplate")}
          />
        </div> */}
        <div className="other-actions">
          <h4>Delete project</h4>
          <p>
            Permanently delete your project and all of its assets. This action
            can not be undone.
          </p>
          <Button className="delete-btn" onClick={deleteHandler}>
            Delete this project
          </Button>
        </div>
        <FormActions
          applyLoader={isLoading}
          resetPreview={resetPreview}
          applyPreview={applyPreview}
          resetHandler={resetHandler}
          applyHandler={applyHandler}
        />
      </Form>
    </AccordionBox>
  );
};

export default DetailAccordion;
