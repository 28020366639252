import React, { useState, useCallback } from "react";
import { Tooltip } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRepeat,
  faThumbsUp as faThumbsUpSolid,
  faThumbsDown as faThumbsDownSolid,
  faCircleCheck,
  faCommentDots,
  faFlag as faFlagSolid,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCopy,
  faFlag,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import { PulseLoader } from "react-spinners";
import { Markdown } from "../../../../../components";
import clsx from "clsx";
import FeedbackModal from "../feedback";
import { CustomDispatch } from "../../../../../helpers/custom-dispatch";
import {
  deleteFeedbackRequest,
  sendFeedbackRequest,
} from "../../../../../redux/slicers/chat";
import { isValidURL } from "../../../../../utils";
import "./styles.scss";

const MessageBox = React.forwardRef(
  (
    {
      data,
      isLoading,
      reGenerateMessage,
      previousMessage,
      selectedProject,
      promptThumb,
      scrollToBottom = () => {},
    },
    ref
  ) => {
    // STATES
    const [isCopied, setCopied] = useState(false);
    const [reportPreview, setReportPreview] = useState(false);

    // CONST VALS
    const isLiked = data?.feedback?.reaction === "like";
    const isDisliked = data?.feedback?.reaction === "dislike";
    const isReported = data?.feedback?.report;

    // CUSTOM DISPATCH
    const [sendFeedback] = CustomDispatch(sendFeedbackRequest);
    const [deleteFeedback] = CustomDispatch(deleteFeedbackRequest);

    // HELPERS
    const submitFeedbackHelper = ({
      reaction = data?.feedback?.reaction,
      report = data?.feedback?.report,
      reason = data?.feedback?.reason,
      rating = data?.feedback?.rating,
    }) => {
      const isDeleteReq = !reaction && !report && !reason && !rating;
      const payload = {
        method: isDeleteReq ? "delete" : "put",
        details: { query_id: data.id },
      };
      if (isDeleteReq) {
        deleteFeedback({ payload });
        return;
      }
      payload.details["feedback"] = {
        reaction,
        report,
        reason,
        rating,
      };
      sendFeedback({ payload });
    };

    // HANDLERS
    const handleReport = () => {
      setReportPreview(!reportPreview);
    };
    const handleLike = () => {
      submitFeedbackHelper({ reaction: isLiked ? null : "like" });
    };
    const handleDislike = () => {
      submitFeedbackHelper({ reaction: isDisliked ? null : "dislike" });
    };
    const handleCopy = () => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    };

    // CONST VALS
    const sources = data?.sources?.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.source_name === obj.source_name)
    );
    const sourcesPreview = sources?.length >= 1;
    const messageOptions = [
      {
        icon: isLiked ? faThumbsUpSolid : faThumbsUp,
        title: isLiked ? "Unlike" : "Like",
        onClick: handleLike,
        isActive: isLiked,
      },
      {
        icon: isDisliked ? faThumbsDownSolid : faThumbsDown,
        title: isDisliked ? "Undislike" : "Dislike",
        onClick: handleDislike,
        isActive: isDisliked,
      },
      {
        icon: isReported ? faFlagSolid : faFlag,
        title: `Report${isReported ? "ed" : ""}`,
        onClick: handleReport,
        isActive: isReported,
      },
      {
        title: isCopied ? "Copied" : "Copy",
        child: (
          <CopyToClipboard text={data.message} onCopy={handleCopy}>
            <button className={isCopied ? "active" : ""}>
              <FontAwesomeIcon icon={isCopied ? faCircleCheck : faCopy} />
            </button>
          </CopyToClipboard>
        ),
      },
    ];

    // CUSTOM COMPONENTS
    const UserMessage = () => {
      return (
        <div className="message-content" ref={ref}>
          <span className="markdown-body">{data.message}</span>
          <Tooltip title={isLoading ? "" : "Regenerate"}>
            <FontAwesomeIcon
              icon={faRepeat}
              onClick={() => {
                !isLoading && reGenerateMessage(data.message);
              }}
              className="regenerate"
              style={{
                opacity: isLoading ? 0.5 : 1,
                cursor: isLoading ? "auto" : "pointer",
              }}
            />
          </Tooltip>
        </div>
      );
    };

    const PromptMessage = () => {
      return (
        <div ref={ref}>
          <div className="top-box">
            <div className="thumbnail">
              {/* {promptThumb} */}
              <span className="title">
                {selectedProject?.botName ?? data?.model ?? "GPT"}
              </span>
            </div>
            {data.isSpeech && (
              <div className="speech-box" data-testid="speech-box">
                <FontAwesomeIcon icon={faCommentDots} />
                <span>Speech</span>
              </div>
            )}
          </div>
          {data.isLoading ? (
            <div className="message-loader" data-testid="message-loader">
              <PulseLoader size={8} />
            </div>
          ) : (
            <>
              <div className={`message-content ${data.isError ? "error" : ""}`}>
                <Markdown>{data.message}</Markdown>
              </div>
              {data.isError && (
                <button
                  className="regenerate-btn"
                  onClick={() => reGenerateMessage(previousMessage.message)}
                >
                  Regenerate
                </button>
              )}

              {data?.isCompleted && (
                <div
                  className={clsx("bottom-box", sourcesPreview && "sourced")}
                >
                  {sourcesPreview && (
                    <ul className="sources">
                      <li className="title">Sources:</li>
                      {sources.map((item, index) => {
                        const isLink = isValidURL(item?.source_name);
                        const pageNumer = item?.page_number
                          ? `- Page ${item?.page_number}`
                          : "";
                        const isLongSource = item?.source_name.length > 30;
                        const source = `${
                          isLongSource
                            ? item?.source_name.slice(0, 30) + "..."
                            : item?.source_name
                        } ${pageNumer}`;
                        const sourceData = () => (
                          <>
                            <span className="number">{index + 1}</span>
                            {isLongSource ? (
                              <Tooltip title={item?.source_name}>
                                <span className="source">{source}</span>
                              </Tooltip>
                            ) : (
                              <span className="source">{source}</span>
                            )}
                          </>
                        );
                        return (
                          <li key={index} className="source-box">
                            {isLink ? (
                              <a
                                href={item?.source_name}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {sourceData()}
                              </a>
                            ) : (
                              sourceData()
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div className="action-box">
                    {messageOptions.map((option, index) => (
                      <Tooltip title={option.title} key={index}>
                        {option.child ? (
                          option.child
                        ) : (
                          <button
                            onClick={option.onClick}
                            className={option.isActive ? "active" : ""}
                          >
                            <FontAwesomeIcon icon={option.icon} />
                          </button>
                        )}
                      </Tooltip>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      );
    };

    // HOOKS
    const elementRef = useCallback((node) => {
      if (!node) return;
      const resizeObserver = new ResizeObserver(() => scrollToBottom());
      resizeObserver.observe(node);
    }, []);

    return (
      <>
        <div
          className={`message-box ${data.isPrompt ? "response" : "request"}`}
          ref={elementRef}
        >
          {data.isPrompt ? <PromptMessage /> : <UserMessage />}
        </div>
        <FeedbackModal
          isReported={isReported}
          preview={reportPreview}
          data={data?.feedback}
          previewHandler={handleReport}
          submitHandler={submitFeedbackHelper}
        />
      </>
    );
  }
);

export default MessageBox;
